import Url from 'o365.modules.url.ts';
import { ref, onMounted } from 'vue';

export default function useContainer(props: IContainerProps) {
    let hideNavbar = false;

    if (Url.getParam('hidenavbar') || Url.getParam('HideNav')) {
        hideNavbar = true;
    }

    const containerRef = ref<HTMLElement>();

    onMounted(() => {
        if (props.desktop || props.appContainer) {
            containerRef.value?.classList.add('app-container');
            containerRef.value?.classList.add('container-scope');
        } else {
            containerRef.value?.classList.add('h-100');
        }

        if ((props.desktop || props.appContainer) && (hideNavbar || props.noToolbar)) {
            containerRef.value?.classList.add('no-toolbar')
        }
    });

    return [containerRef];
}

interface IContainerProps {
    desktop: boolean;
    appContainer: boolean;
    noToolbar: boolean;
};